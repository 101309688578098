import React from "react";

const ScientificProgram = () => {
  const imgArr = [];
  const detailsArr = [
    {
      name: "Dr S Premkumar",
      posttion: "Dean",
      college: "TN Govt Dental College ",
    },
    {
      name: "Dr Suma K. ",
      posttion: "Dean",
      college: "Rajah Muthiah Dental College",
    },
    {
      name: "Dr. J. Sabarinathan",
      posttion: "Pro Vice Chancellor",
      college: "Vinayaka Mission’s Research Foundation ",
    },
    {
      name: "Dr S P K Kennedy ",
      posttion: "Dean",
      college: "Mahatma Gandhi PG institute",
    },
    {
      name: "Dr Alex Mathew Muruppel",
      posttion: "Dean",
      college: "Rajas Dental College",
    },
    {
      name: "Dr M. S. Kannan",
      posttion: "Dean",
      college: "Sree Balaji Dental college",
    },
    {
      name: "Dr H. Tamizhchelvan",
      posttion: "Dean",
      college: "Sri Ramachandra Dental college",
    },
    {
      name: "Dr R Hariharan",
      posttion: "Dean",
      college: "Thai Moogambigai Dental College",
    },
    {
      name: "Dr L Deepanandan",
      posttion: "Dean",
      college: "Sri Ramakrishna Dental College",
    },
    {
      name: "Dr U. Arunmozhi",
      posttion: "Dean",
      college: "Sri Venkateshwara Dental College",
    },
    {
      name: "Dr P Rajesh",
      posttion: "Dean",
      college: "Chettinad Dental College and Research Institute",
    },
    {
      name: "Dr V K Vijay",
      posttion: "Dean",
      college: "RVS Dental College",
    },
    {
      name: "Dr Shahul Hameed Faizee",
      posttion: "Dean",
      college: "Sathyabama Dental College",
    },

    {
      name: "Dr Bala Guhan",
      posttion: "Principal",
      college: "Karpaga Vinayaga Inst. of Dental Sciences",
    },
    {
      name: "Dr Malathi Dayalan",
      posttion: "Dean",
      college: "BEST Dental College",
    },
    {
      name: "Dr R. Anil Kumar",
      posttion: "Dean",
      college: "Ragas Dental College",
    },
    {
      name: "Dr N. Velmurugan",
      posttion: "Dean",
      college: "Meenakshi Ammal Dental College",
    },
    {
      name: "Dr Thanvir Mohammed Niazi",
      posttion: "Principal",
      college: "CSI College of Dental Sciences and Research",
    },
    {
      name: "Dr S. Nagalaxmi",
      posttion: "Dean",
      college: "Vivekananda Dental College for Women",
    },
    {
      name: "Dr  C. J. Venkatakrishnan",
      posttion: "Principal",
      college: "Tagore Dental College",
    },
    {
      name: "Dr. Bagavad Gita",
      posttion: "Principal",
      college: "Madha Dental College",
    },
    {
      name: "Dr Elizabeth Koshi",
      posttion: "Principal",
      college: "Sree Mookanbika Inst. of Dentistry",
    },
    {
      name: "Dr Rajesh Pillai",
      posttion: "Principal",
      college: "MS College of Dental Science and Research",
    },
    {
      name: "Dr A Murugananthan",
      posttion: "Tamil Nadu Medical Council",
      college: "",
    },
    {
      name: "Dr Ajay Logani",
      posttion: "Prof & Head Conservative Dentistry CIDER , AIIMS",
      college: "",
    },
    {
      name: "Dr M Arvind",
      posttion: "Prof & Head",
      college: "Saveetha Dental College and Hospital",
    },
    {
      name: "Dr Anusha D",
      posttion: "Professor",
      college: "Sri Ramachandra Medical college",
    },
    {
      name: "Dr Balaji K",
      posttion: "Professor and Head ",
      college: "Tagore Dental College",
    },
    {
      name: "Dr Vijay Adhith",
      posttion: "Professor and Head",
      college: "Priyadharshini Dental College",
    },
    {
      name: "Dr. V. Rangarajan",
      posttion: "Advisor",
      college: "Sri Venkateswara Dental College",
    },
    {
      name: "Dr. Dhandapani Arunachalam",
      posttion: "Private practitioner",
      college: "",
    },
    {
      name: "Dr. Bharath Kumar Jayam",
      posttion: "Private practitioner",
      college: "",
    },
    {
      name: "Dr John Nesan",
      posttion: "Director: CTARS - Centre for Technology Assisted Reconstructive Surgery",
      college: "",
    },
    {
      name: "Dr. Rooban Thavarajah",
      posttion: "Professor",
      college: "Ragas Dental College & Hospital, Chennai",
    },
    {
      name: "Dr Navaneetha",
      posttion: "Prof and Head,",
      college: "Madha Dental College and Hospital",
    },
    {
      name: "Dr. Sudeep Sarathchandran",
      posttion: "Prof and Head,",
      college: "PMS College of Dental Science & Research",
    },
    {
      name: "Prof. Ruwan Jayasinghe",
      posttion: "Senior Professor and Chair, Department of Oral Medicine, and Periodontology Faculty of Dental Sciences",
      college: "University of Peradeniya, Sri Lanka",
    },
    {
      name: "Dr. Hoang Trong Hung",
      posttion: "Vice-Dean, Head, Faculty of OdontoStomatology, University of Medicine and Pharmacy",
      college: "Ho Chi Minh city, Vietnam",
    },
    {
      name: "Dr. Sundhar Raj",
      posttion: "Oral and Maxillofacial Surgery NHS",
      college: "United Kindom",
    },
    {
      name: "Dr. C. Saravanan",
      posttion: "Oral and Maxillofacial Surgeon",
      college: "USA",
    },
    {
      name: "Dr. Satyabama Saravanan ",
      posttion: "Oral and Maxillofacial Surgery",
      college: "USA",
    },
    {
      name: "Dr. Gauri Pradhan",
      posttion: "UK",
      college: "",
    },
    {
      name: "Dr. Shivani Bhandari",
      posttion: "UK",
      college: "",
    },
    {
      name: "Dr. Neil Carmichael",
      posttion: "UK",
      college: "",
    },
    {
      name: "Dr. Pip Dhariwal",
      posttion: "UK",
      college: "",
    },
  ];
  for (let i = 1; i < detailsArr.length + 1; i++) {
    imgArr.push({
      src: `./img/speaker/speaker${i}.webp`,
      description: detailsArr[i - 1],
    });
  }
  console.log(imgArr.src);
  return (
    <div>
      <section className="container-fluid my-5 py-5 col-lg-10 col-md-10 col-11">
        <h1 className="text-center inter_head">SPEAKER</h1>
        <div className="row mt-4 py-4" id="imageGrid">
          {imgArr.map((data, i) => {
            return (
              <div
                key={i}
                className="flex align-items-center justify-content-center flex-column py-4 col-12 col-md-6 col-lg-4"
              >
                <img
                  key={i}
                  style={{ cursor: "pointer" }}
                  className="mx-auto rounded img-fluid d-block py-2 center-block animatedimgs"
                  width="80%"
                  src={data.src}
                  alt="hiii"
                />
                <div className="py-2 px-2">
                  <p className="text-center mb-0 patron_head">
                    {data.description?.name}
                  </p>
                  <p className="text-center mb-1 ">
                    {data.description?.posttion}
                  </p>
                  <p className="text-center mb-0 ">
                    {data.description?.college}
                  </p>
                  <p className="text-center mb-0 ">{data.description?.topic}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ScientificProgram;
